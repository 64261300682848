<template>
  <div>
    <div class="title">卡片详情</div>
    <div class="tishi" v-if="msg">
      <span class="p1" v-html="msg"></span>
      <span class="p2" @click="show = true">详情</span>
      <van-popup style="padding:20px;width: 70%;" v-model="show" v-html="msg"></van-popup>
    </div>
    <van-field
      v-model="chinese_name"
      type="text"
      label="中文姓名"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="name"
      type="text"
      label="英文姓名"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="mobile"
      type="text"
      label="手机号"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="currency"
      type="text"
      label="币种"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="card_name"
      type="text"
      label="卡片"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="card_no"
      type="text"
      label="卡号"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="address"
      type="textarea"
      label="地址"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="created_at"
      type="text"
      label="创建时间"
      :label-width="100"
      readonly
    />
    <van-field
      v-model="statusTxt"
      type="text"
      label="状态"
      :label-width="100"
      readonly
    />
    <div class="fixed" v-if="status == 3">
			<van-button  type="primary" @click="pass=true">设置密码</van-button>
		</div>
    <van-dialog
      v-model="pass"
      title="设置密码"
      show-cancel-button
      @confirm="setPassword"
    >
      <van-field
        v-model="pin"
        type="password"
        label="新密码"
        maxlength="6"
        placeholder="请输入纯数字6位密码"
        :label-width="70"
      />
      <van-field
        v-model="pin2"
        type="password"
        label="确认密码"
        maxlength="6"
        placeholder="请输入纯数字6位密码"
        :label-width="70"
      />
    </van-dialog>
  </div>
</template>
<script>
  export default {
		name: 'personal_wan',
		data() {
			return {
        kyc:true,
        show:false,
        step:0,
				name:'',
				mobile:'',
				currency:'',
				card_name:'',
				address:'',
				card_no:'',
				created_at:'',
				chinese_name:'',
        cancel:0,
        kycEnable:0,
        kycAddEnable:0,
        msg:'',
        status:'',
        statusTxt:'',
        pass:false,
        pin:'',
        pin2:'',
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
      this.getProtocol()
			this.getNewest()
		},
		methods: {
      setPassword(){
        if(this.pin != this.pin2){
          this.$dialog.alert({
            message: '前后密码不一致'
          })
          return
        }
        if(this.pin.length != 6){
          this.$dialog.alert({
            message: '密码必须为6位数字'
          })
          return
        }
        if(/^\d+$/.test(this.pin) == false){
          this.$dialog.alert({
            message: '密码必须为6位数字'
          })
          return
        }
        this.$axios({
          method:'post',
          url:'speedpay_card/atmpinMaster',
          data:{
						token:this.$route.query.token,
            card_type:this.$route.query.card_type,
            card_no:this.card_no,
            pin:this.pin
					}
				}).then ((res) => {
					if (res.success) {
            this.getNewest()
            this.$dialog.alert({
							message: '设置成功'
						})
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      
			getProtocol(){
        this.$axios({
					method: 'post',
					url: 'speedpay_user/index', 
					data: {
            token: this.$route.query.token,
            card_type:this.$route.query.card_type
          }
				}).then ((res) => {
					if (res.success) {
            this.step=res.data.step;
            this.reapply=res.data.reapply;
            this.kycEnable=res.data.kycEnable;
            this.kycAddEnable=res.data.kycAddEnable;
            this.cancel=res.data.cancel;
            this.msg=res.data.msg;
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      getNewest(){
				this.$axios({
					method: 'post',
					url: 'speedpay_card/newest',
					data: {
						token:this.$route.query.token,
            card_type:this.$route.query.card_type
					}
				}).then ((res) => {
					if (res.success) {
            console.log(res)
            this.name = res.data.name
            this.mobile = res.data.mobile
            this.currency = res.data.currency
            this.card_name = res.data.card_name
            this.address = res.data.address
            this.card_no = res.data.card_no
            this.created_at = res.data.created_at
            this.chinese_name = res.data.chinese_name
            this.status = res.data.status
            this.statusZh(this.status)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			
      // status：1: 审核中，2: 审核通过，3：已发卡，4：已激活，5： 已实名，9：被驳回，10：已取消
      statusZh(status){
        switch (status) {
          case '1':
            this.statusTxt = '审核中'
            break;
          case '2':
            this.statusTxt = '审核通过'
            break;
          case '3':
            this.statusTxt = '已发卡'
            break;
          case '4':
            this.statusTxt = '已激活'
            break;
          case '5':
            this.statusTxt = '已实名'
            break;
          case '9':
            this.statusTxt = '被驳回'
            break;
          case '10':
            this.statusTxt = '已取消'
            break;
          default:
            break;
        }
      },
      
		},
	}
</script>
<style scoped>
  .title{
    font-weight: bold;
    text-align: center;
    padding: 15px 0;
    text-align: center;
    background: #fff;
    margin-bottom: 10px;
  }
  .tishi{
    padding: 20px;
    background: #fff;
    margin-bottom: 15px;
    display: flex;
  }
  .tishi .p1{
    flex:8;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
  .tishi .p2{
    flex:2;
    text-align: center;
    font-size: 12px;
    color: #818181;
  }
  .fixed{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 999;
		background: #fff;
		padding: 10px;
		box-sizing: border-box;
		box-shadow: 0px 0px 10px #ccc;
		display: flex;
		justify-content: space-around;
	}
</style>